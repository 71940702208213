import {
  Directive,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthQuery } from '../../../state/auth/auth.query';
import { AuthService } from '../../../state/auth/auth.service';

@Directive({
  selector: '[estiIfLoggedIn]',
})
export class IfLoggedInDirective implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  constructor(
    private authService: AuthService,
    protected authQuery: AuthQuery,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.subs.push(
      this.authService.sync().subscribe(),
      this.shouldShow$()
        .pipe(distinctUntilChanged())
        .subscribe((shouldShow) => {
          this.viewContainerRef.clear();
          if (shouldShow) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        })
    );
  }

  shouldShow$() {
    return this.authQuery.isLoggedIn$;
  }

  ngOnDestroy() {
    this.subs.forEach((e) => e.unsubscribe());
    this.subs = [];
  }
}
