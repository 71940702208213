<ng-container *estiIfLoggedIn>
  <main>
    <router-outlet></router-outlet>
  </main>
  <header>
    <esui-online-status></esui-online-status>
    <a mat-button routerLink="/">Task Island <mat-icon>surfing</mat-icon></a>
    <span class="spacer"></span>
    <esui-hotkeys-cheatsheet></esui-hotkeys-cheatsheet>
    <a
      mat-button
      [href]="
        'mailto:development-team+taskislandfeedback@expresssteuer.com?subject=Feedback%20%3Ctask%20island%3E%20' +
        buildMeta.commitHash +
        '&amp;'
      "
      >feedback</a
    >
    <esti-user-header></esti-user-header>
  </header>
</ng-container>
<main class="loggedout" *estiIfLoggedOut>
  <span class="spacer"></span>
  <esti-auth> </esti-auth>
  <span class="spacer"></span>
  <aside>
    <esui-pixar></esui-pixar>
    <p>Made with ♥ in Hamburg, Germany</p>
    <p>Build {{ buildMeta.commitHash }} {{ buildMeta.date }}</p>
  </aside>
</main>
