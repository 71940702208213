import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {
  AngularFireAuth,
  USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { SETTINGS } from '@angular/fire/compat/firestore';
import {
  REGION,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/compat/functions';
import {
  connectFirestoreEmulator,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { ESUI_IBAN_VALIDATOR } from '@expresssteuer/angular-validators';
import { ValidateIban } from '@expresssteuer/iban-api-angular';
import {
  BrowserOnlineStatusService,
  EsHotkeysModule,
  EsLogoModule,
  EsOnlineStatusModule,
  EsuiPixarModule,
  HREF_BUILDER,
  LOGGER_CONFIG,
  LOGGER_LEVEL,
  OBJECTS_TO_EXPOSE,
  ONLINE_STATUS_SERVICE,
  buildImportsForRoot,
  buildProvidersForRoot,
  providersForDedicatedLogger,
} from '@expresssteuer/ui-components';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { EstiDebugObjectsService } from './services/esti-debug-objects.service';

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot([], { initialNavigation: 'enabledBlocking' }),
    ...buildImportsForRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.emulator) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      return firestore;
    }),
    provideAuth(() => getAuth()),
    environment.production ? [] : AkitaNgDevtools.forRoot({ logTrace: false }),
    AkitaNgRouterStoreModule,
    BrowserAnimationsModule,
    AuthModule,
    EsHotkeysModule.forRoot(),
    EsLogoModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    AppRoutingModule,
    EsOnlineStatusModule,
    EsuiPixarModule,
    HttpClientModule,
  ],
  providers: [
    ...buildProvidersForRoot(),
    ...providersForDedicatedLogger({ scopeLabel: AppModule.name }),
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.emulator ? ['http://localhost:9099'] : undefined,
    },
    //#region auth emulator fix
    // Currently the auth emulator clears the auth state on refresh.
    // It's supposed to be fixed in V9 https://github.com/firebase/firebase-js-sdk/issues/4110
    // Until then, the following assures `AngularFireAuth` is initialized first I guess...
    // TODO remove this region after v9 upgrade
    environment.emulator
      ? {
          provide: APP_INITIALIZER,
          useFactory: function (_: AngularFireAuth) {
            return () => void 0;
          },
          deps: [AngularFireAuth],
          multi: true,
        }
      : [],
    //#endregion
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.emulator ? ['localhost', 9000] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.emulator ? ['localhost', 5001] : undefined,
    },
    { provide: REGION, useValue: 'europe-west3' },
    {
      provide: SETTINGS,
      useValue: environment.emulator
        ? {
            host: 'localhost:8080',
            ssl: false,
          }
        : undefined,
    },
    {
      provide: ONLINE_STATUS_SERVICE,
      useClass: BrowserOnlineStatusService,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: LOGGER_LEVEL,
      useValue: environment.level,
    },
    {
      provide: LOGGER_CONFIG,
      useValue: {
        showScopeLabels: true,
        showOwnerNames: true,
        showTimeStamps: false,
      },
    },
    {
      provide: OBJECTS_TO_EXPOSE,
      useClass: EstiDebugObjectsService,
    },
    {
      provide: HREF_BUILDER,
      useValue: {
        taskIsland: {
          classic: {
            task: (taskPath?: string) => {
              if (!taskPath) {
                return null;
              }
              // eslint-disable-next-line no-restricted-globals
              return `${window.location.origin}/main?taskFilter=(taskPath:${taskPath})`;
            },
            client: (clientId?: string) => {
              if (!clientId) {
                return null;
              }
              // eslint-disable-next-line no-restricted-globals
              return `${window.location.origin}/main?taskFilter=(clientId:${clientId})`;
            },
            taxcase: (taxcaseId?: string) => {
              if (!taxcaseId) {
                return null;
              }
              // eslint-disable-next-line no-restricted-globals
              return `${window.location.origin}/main?taskFilter=(taxcaseId:${taxcaseId})`;
            },
          },
          crm: (clientId?: string) => {
            if (!clientId) {
              return null;
            }
            // eslint-disable-next-line no-restricted-globals
            return `${window.location.origin}/client?client-view=(id:${clientId})`;
          },
        },
        crm: {
          client: (clientId?: string) => {
            if (!clientId) {
              return null;
            }
            return `${environment.crmUrl}/client/${clientId}`;
          },
          taxcase: (clientId?: string, taxcaseId?: string) => {
            if (!clientId || !taxcaseId) {
              return null;
            }
            return `${environment.crmUrl}/client/${clientId}/taxcase/${taxcaseId}`;
          },
        },
      },
    },
    { provide: ESUI_IBAN_VALIDATOR, useExisting: ValidateIban },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
