<ng-container *ngLet="authIsLoading$ | async as authIsLoading">
  <div *ngIf="authIsLoading">
    <esui-spinner></esui-spinner>
    <p>logging in...</p>
  </div>

  <ng-container *ngIf="!authIsLoading">
    <ng-container *ngLet="isLoggedIn$ | async as isLoggedIn">
      <ng-container *ngIf="!isLoggedIn">
        <div class="title">
          <h1>Task Island</h1>
          <h2>by <eg-logo></eg-logo></h2>
        </div>
        <div>
          <esui-button (click)="loginWithGoogle()"
            >login with google</esui-button
          >
        </div>
      </ng-container>
      <ng-container *ngIf="isLoggedIn">
        <div>
          <ng-content></ng-content>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
