import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermKey } from '@expresssteuer/models';
import { AuthSyncGuard } from './modules/auth/guards/auth-sync.guard';
import { HasPermGuard } from './modules/auth/guards/has-perm.guard';
import { HasRoleGuard } from './modules/auth/guards/has-role.guard';
import { appRoutes } from './views/app-routes';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: appRoutes.dashboard.path },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: appRoutes.dashboard.path,
    loadChildren: () =>
      import('./views/dashboard-view/dashboard-view.module').then(
        (m) => m.DashboardViewModule
      ),
    canActivate: [AuthSyncGuard],
  },
  {
    path: appRoutes.longIsland.path,
    loadChildren: () =>
      import('./views/long-island-view/long-island-view.module').then(
        (m) => m.LongIslandViewModule
      ),
    canActivate: [AuthSyncGuard],
  },
  {
    path: appRoutes.client.path,
    loadChildren: () =>
      import('./views/client-view/client-view.module').then(
        (m) => m.ClientViewModule
      ),
    canActivate: [AuthSyncGuard],
  },
  {
    path: appRoutes.admin.path,
    loadChildren: () =>
      import('./views/admin-view/admin-view.module').then(
        (m) => m.AdminViewModule
      ),
    canActivate: [AuthSyncGuard, HasRoleGuard, HasPermGuard],
    data: { roles: ['admin', 'user'], perm: PermKey.INTERNAL_USERS },
  },
  {
    path: appRoutes.profile.path,
    loadChildren: () =>
      import('./views/profile-view/profile-view.module').then(
        (m) => m.ProfileViewModule
      ),
    canActivate: [AuthSyncGuard],
  },
  {
    path: appRoutes.assemblyLine.path,
    loadChildren: () =>
      import('./views/main-view/main-view.module').then(
        (m) => m.MainViewModule
      ),
    canActivate: [AuthSyncGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [HasRoleGuard, AuthSyncGuard],
})
export class AppRoutingModule {}
