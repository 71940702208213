import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  increment as databaseIncrement,
  serverTimestamp as databaseServerTimestamp,
} from '@angular/fire/database';
import {
  increment as firestoreIncrement,
  serverTimestamp as firestoreServerTimestamp,
} from '@angular/fire/firestore';
import {
  Claims,
  ClaimsParsed,
  UserPresence,
  UserPresenceRealtimeDB,
} from '@expresssteuer/models';
import { EsuiLoggerService } from '@expresssteuer/ui-components';
import {
  CollectionConfig,
  FireAuthService,
  getCustomClaims,
} from 'akita-ng-fire';
import { tap } from 'rxjs/operators';
import { AuthQuery } from './auth.query';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'internalusers' })
export class AuthService extends FireAuthService<AuthState> {
  private logger: EsuiLoggerService;

  constructor(
    store: AuthStore,
    logger: EsuiLoggerService,
    private authQuery: AuthQuery,
    private angularFireDatabase: AngularFireDatabase,
    private angularFirestore: AngularFirestore
  ) {
    super(store);
    this.logger = logger.getNewInstance(this);
  }

  override signOut(): Promise<void> {
    // goOffline to trigger the onDisconnect hook
    this.angularFireDatabase.database.goOffline();
    return super.signOut();
  }

  override createProfile(user: User): Partial<AuthState['profile']> {
    this.logger.debug('AuthService:createProfile for ', user);

    if (!user.email || !user.displayName) {
      throw Error('You are invalid');
    }
    return {
      // uid should not be stored in Profile
      email: user.email,
      photoUrl: user.photoURL,
      displayName: user.displayName,
    };
  }

  override async selectRoles(user: User): Promise<AuthState['roles']> {
    // currently we do not follow the concept of roles, but claims, therefore lets handle claims at this point:
    const { parsedClaims, rawClaims } = await this.selectClaims(user);
    this.store.update({ rawClaims });
    return parsedClaims;
  }

  private async selectClaims(user: User) {
    const rawClaims = (await getCustomClaims(user)) as Claims;
    const parsedClaims = ClaimsParsed.fromClaims(rawClaims);
    return { parsedClaims, rawClaims };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override formatFromFirestore(user: any): AuthState['profile'] {
    return user;
  }

  onCreate() {
    this.logger.debug('Logged from onCreate hook');
  }

  onDelete() {
    this.logger.debug('Logged from onDelete hook');
  }

  onUpdate() {
    this.logger.debug('Logged from onUpdate hook');
  }

  onSignup() {
    this.logger.debug('Logged from onSignup hook');
  }

  onSignin() {
    this.logger.debug('Logged from onSignin hook');

    // goOnline as signOut sets goOffline
    this.angularFireDatabase.database.goOnline();
  }

  onSignout() {
    this.logger.debug('You have been signed out. Logged from onSignout hook');
  }

  public syncOnlineStatus() {
    return this.authQuery.uid$.pipe(
      tap((uid) => {
        if (!uid) {
          this.logger.info('no uid specified for syncOnlineStatus');
          return;
        }

        // Create a reference to this user's specific status node.
        // This is where we will store data about being online/offline.
        const userStatusDatabaseRef = this.angularFireDatabase.database.ref(
          `users/${uid}/presence/task-island`
        );
        const userStatusFirestoreRef = this.angularFirestore.doc(
          `internalusers/${uid}/presence/task-island`
        );

        // We'll create two constants which we will write to
        // the Realtime database when this device is offline
        // or online.
        const isOfflineForDatabase: UserPresenceRealtimeDB = {
          activeSessions: databaseIncrement(-1),
          lastChanged: databaseServerTimestamp(),
        };

        const isOnlineForDatabase: UserPresenceRealtimeDB = {
          activeSessions: databaseIncrement(1),
          lastChanged: databaseServerTimestamp(),
        };

        // Firestore uses a different server timestamp value, so we'll
        // create two more constants for Firestore state.
        const isOfflineForFirestore: UserPresence = {
          activeSessions: firestoreIncrement(-1),
          lastChanged: firestoreServerTimestamp(),
        };

        const isOnlineForFirestore = {
          activeSessions: firestoreIncrement(1),
          lastChanged: firestoreServerTimestamp(),
        };

        // Create a reference to the special '.info/connected' path in
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        this.angularFireDatabase.database
          .ref('.info/connected')
          .on('value', (snapshot) => {
            // If we're not currently connected, don't do anything.
            if (snapshot.val() == false) {
              // Instead of simply returning, we'll also set Firestore's state
              // to 'offline'. This ensures that our Firestore cache is aware
              // of the switch to 'offline.'
              userStatusFirestoreRef
                .set(isOfflineForFirestore)
                .catch((err) =>
                  console.warn(`userStatusFirestoreRef.set.error`, err)
                );
              return;
            }

            // If we are currently connected, then use the 'onDisconnect()'
            // method to add a set which will only trigger once this
            // client has disconnected by closing the app,
            // losing internet, or any other means.
            userStatusDatabaseRef
              .onDisconnect()
              .set(isOfflineForDatabase)
              .then(async () => {
                // The promise returned from .onDisconnect().set() will
                // resolve as soon as the server acknowledges the onDisconnect()
                // request, NOT once we've actually disconnected:
                // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                // We can now safely set ourselves as 'online' knowing that the
                // server will mark us as offline once we lose connection.
                await userStatusDatabaseRef
                  .set(isOnlineForDatabase)
                  .catch((error) => {
                    this.logger.error(
                      'set isOnline on userStatusDatabaseRef failed',
                      error
                    );
                  });

                // We'll also add Firestore set here for when we come online.
                await userStatusFirestoreRef
                  .set(isOnlineForFirestore)
                  .catch((error) => {
                    this.logger.error(
                      'set isOnline on userStatusFirestoreRef failed',
                      error
                    );
                  });
              })
              .catch((error) => {
                this.logger.error(
                  'register onDisconnect on userStatusDatabaseRef failed',
                  error
                );
              });
          });
      })
    );
  }
}
