import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
//import { HttpsUpdateClaims } from '@expresssteuer/api-angular'; //required to initalize claims

@Injectable({
  providedIn: 'root',
})
export class EstiDebugObjectsService {
  firestore: AngularFirestore['firestore'];
  constructor(
    public afs: AngularFirestore // public updateClaims: HttpsUpdateClaims //required to initalize claims
  ) {
    this.firestore = afs.firestore;
  }
}
