import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  isLONGTASKTYPE,
  isTASKTYPE,
  LONGTASKTYPE,
  longTaskTypeFromPermKey,
  PermKey,
  TASKTYPE,
  taskTypeFromPermKey,
} from '@expresssteuer/models';
import { combineLatest, filter, map } from 'rxjs';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  profile$ = this.select('profile');
  uid$ = this.select('uid');
  isLoggedIn$ = this.select('profile').pipe(map((e) => !!e?.displayName));
  perms$ = this.select((state) => state.roles?.perms).pipe(filter((e) => !!e));
  role$ = this.select((state) => state.roles?.role).pipe(filter((e) => !!e));
  claims$ = this.select((state) => state.roles).pipe(filter((e) => !!e));
  rawClaims$ = this.select((s) => s.rawClaims);
  isAdmin$ = this.role$.pipe(map((role) => role === 'admin'));
  canListAllTaskTypes$ = this.perms$.pipe(
    map((perms) => {
      return perms?.['t.all']?.canList === true;
    })
  );
  listableTaskTypes$ = combineLatest([
    this.isAdmin$,
    this.canListAllTaskTypes$,
    this.perms$,
  ]).pipe(
    map(([isAdmin, canListAllTaskTypes, perms]) => {
      return isAdmin || canListAllTaskTypes
        ? Object.values(TASKTYPE)
        : Object.entries(perms)
            .filter(([, value]) => value.canList === true)
            .map(([key]) => {
              const type = taskTypeFromPermKey(key as PermKey);
              return type;
            })
            .filter((e): e is TASKTYPE => isTASKTYPE(e));
    })
  );
  canListAllLongTaskTypes$ = this.perms$.pipe(
    map((perms) => {
      return perms?.['lt.all']?.canList === true;
    })
  );
  listableLongTaskTypes$ = combineLatest([
    this.isAdmin$,
    this.canListAllTaskTypes$,
    this.perms$,
  ]).pipe(
    map(([isAdmin, canListAllLongTaskTypes, perms]) => {
      return isAdmin || canListAllLongTaskTypes
        ? Object.values(LONGTASKTYPE)
        : Object.entries(perms)
            .filter(([, value]) => value.canList === true)
            .map(([key]) => {
              const type = longTaskTypeFromPermKey(key as PermKey);
              return type;
            })
            .filter((e): e is LONGTASKTYPE => isLONGTASKTYPE(e));
    })
  );
  canSeeSomeTasks$ = this.listableTaskTypes$.pipe(
    map((listableTaskTypes) => listableTaskTypes.length > 0)
  );
  canSeeSomeLongTasks$ = this.listableLongTaskTypes$.pipe(
    map((listableLongTaskTypes) => listableLongTaskTypes.length > 0)
  );
  publicSessionId$ = this.select('publicSessionId');
  errors$ = this.selectError();

  constructor(protected store: AuthStore) {
    super(store);
  }
}
