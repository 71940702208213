import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../state/auth/auth.service';

/**
 * Keeps Auth Store in Sync, does not really guard
 */
@Injectable({
  providedIn: 'root',
})
export class AuthSyncGuard implements CanActivate, CanDeactivate<unknown> {
  subs: Subscription[] = [];
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    this.subs.push(
      this.authService.sync().subscribe(),
      this.authService.syncOnlineStatus().subscribe()
    );
    return true;
  }

  canDeactivate(): boolean {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    return true;
  }
}
