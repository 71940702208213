import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EsuiLoggerService,
  HotkeysService,
} from '@expresssteuer/ui-components';
import { Subscription } from 'rxjs';
import { AuthQuery } from '../../../../state/auth/auth.query';
import { AuthService } from '../../../../state/auth/auth.service';

@Component({
  selector: 'esti-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [EsuiLoggerService],
})
export class UserComponent implements OnDestroy, OnInit {
  user$ = this.authQuery.profile$;
  role$ = this.authQuery.role$;
  perms$ = this.authQuery.perms$;

  subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private hotkeysService: HotkeysService,
    private logger: EsuiLoggerService
  ) {
    this.logger.registerOwner(this);
  }

  logout() {
    return this.authService.signOut().catch((err) => {
      this.logger.error(err);
      throw err;
    });
  }

  ngOnInit() {
    this.subscriptions.push(this.authService.sync().subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((e) => e.unsubscribe());
    this.subscriptions = [];
  }
}
