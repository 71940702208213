import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EsuiLoggerService,
  HotkeysService,
} from '@expresssteuer/ui-components';
import { Subscription } from 'rxjs';
import { AuthQuery } from '../../../../state/auth/auth.query';
import { AuthService } from '../../../../state/auth/auth.service';

@Component({
  selector: 'esti-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  providers: [EsuiLoggerService],
})
export class AuthComponent implements OnDestroy, OnInit {
  user$ = this.authQuery.profile$;
  isLoggedIn$ = this.authQuery.isLoggedIn$;
  authIsLoading$ = this.authQuery.selectLoading();

  subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private hotkeysService: HotkeysService,
    private logger: EsuiLoggerService
  ) {
    this.logger.registerOwner(this);
  }

  loginWithGoogle() {
    return this.authService.signin('google').catch((err) => {
      this.logger.error(err);
      throw err;
    });
  }

  logout() {
    return this.authService.signOut().catch((err) => {
      this.logger.error(err);
      throw err;
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authService.sync().subscribe(),
      this.hotkeysService
        .register({
          combo: 'alt+l',
          description: 'login with google',
        })
        .subscribe(() => {
          this.loginWithGoogle().catch((err) =>
            console.warn(`loginWithGoogle.error`, err)
          );
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((e) => e.unsubscribe());
    this.subscriptions = [];
  }
}
