import { LoggingLevel } from '@expresssteuer/ui-components';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  emulator: false,
  level: LoggingLevel.Debug,
  algolia: {
    appId: 'W6Z6VUY52V',
    indexNames: {
      documents_v2: 'dev_documents_v2',
      taxcases: 'dev_taxcases',
      messages: 'dev_messages',
      documents: 'dev_documents',
      clients: 'dev_clients',
    },
  },
  firebase: {
    apiKey: 'AIzaSyA7aHq45T9jOnO6Gsc8_mxNSx7UEKJZPMc',
    authDomain: 'expresssteuer-development.firebaseapp.com',
    projectId: 'expresssteuer-development',
    storageBucket: 'expresssteuer-development.appspot.com',
    messagingSenderId: '849191636990',
    appId: '1:849191636990:web:6de303079cf9f04b87baaf',
    databaseURL:
      'https://expresssteuer-development-default-rtdb.europe-west1.firebasedatabase.app',
    measurementId: 'G-QCKMDL982G',
  },
  crmUrl: 'https://dev-crm-expresssteuer.web.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
