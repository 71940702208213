import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EsuiLoggerService,
  HotkeysService,
} from '@expresssteuer/ui-components';
import { Subscription } from 'rxjs';
import { AuthQuery } from '../../../../state/auth/auth.query';
import { AuthService } from '../../../../state/auth/auth.service';
import { appRoutes } from '../../../../views/app-routes';

@Component({
  selector: 'esti-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
  providers: [EsuiLoggerService],
})
export class UserHeaderComponent implements OnDestroy, OnInit {
  routes = appRoutes;
  user$ = this.authQuery.profile$;
  public role$ = this.authQuery.role$;

  subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private hotkeysService: HotkeysService,
    private logger: EsuiLoggerService
  ) {
    this.logger.registerOwner(this);
  }

  logout() {
    return this.authService.signOut().catch((err) => {
      this.logger.error(err);
      throw err;
    });
  }
  async getJwt() {
    const user = this.authService.auth.currentUser;
    const token = await user?.getIdToken();
    this.logger.info('Your JWT:');
    console.log(token);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authService.sync().subscribe(),
      this.hotkeysService
        .register({
          combo: 'alt+l',
          description: 'logout',
        })
        .subscribe(() => {
          this.logout().catch((err) => console.warn(`logout.error`, err));
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((e) => e.unsubscribe());
    this.subscriptions = [];
  }
}
