export const appRoutes = {
  assemblyLine: {
    path: 'main',
  },
  longIsland: {
    path: 'long',
  },
  admin: {
    path: 'admin',
  },
  profile: {
    path: 'profile',
  },
  dashboard: {
    path: 'dashboard',
  },
  client: {
    path: 'client',
  },
};
