import { Directive } from '@angular/core';
import { map } from 'rxjs/operators';
import { IfLoggedInDirective } from './if-logged-in.directive';

@Directive({
  selector: '[estiIfLoggedOut]',
})
export class IfLoggedOutDirective extends IfLoggedInDirective {
  override shouldShow$() {
    return this.authQuery.isLoggedIn$.pipe(map((e) => !e));
  }
}
