import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  EgLogoModule,
  EsButtonModule,
  EsProgressSpinnerModule,
  EsUserModule,
  providersForDedicatedLogger,
} from '@expresssteuer/ui-components';
import { NgLetModule } from 'ng-let';
import { AuthComponent } from './components/auth/auth.component';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { UserComponent } from './components/user/user.component';
import { IfLoggedInDirective } from './directives/if-logged-in.directive';
import { IfLoggedOutDirective } from './directives/if-logged-out.directive';

@NgModule({
  providers: [...providersForDedicatedLogger({ scopeLabel: AuthModule.name })],
  imports: [
    CommonModule,
    EsButtonModule,
    EsUserModule,
    EsProgressSpinnerModule,
    NgLetModule,
    RouterModule,
    EgLogoModule,
  ],
  declarations: [
    AuthComponent,
    UserComponent,
    UserHeaderComponent,
    IfLoggedInDirective,
    IfLoggedOutDirective,
  ],
  exports: [
    AuthComponent,
    UserComponent,
    IfLoggedInDirective,
    IfLoggedOutDirective,
    UserHeaderComponent,
  ],
})
export class AuthModule {}
