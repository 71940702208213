import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthQuery } from '../../../state/auth/auth.query';

/**
 * Checks if the logged in user claims to have the necessary role
 * 
 * - roles are defined in the data property of routes
 * - waits for a logged in user (should be combined with AuthSyncGuard)
 * 
 * @example
  { 
    path: 'main',
    canActivate: [AuthSyncGuard, HasRoleGuard],
    data: { roles: ['admin', 'user'] },
  },
 */
@Injectable({
  providedIn: 'root',
})
export class HasRoleGuard implements CanActivate {
  constructor(private authQuery: AuthQuery, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!route.data.roles) {
      return true;
    }
    return this.authQuery.isLoggedIn$.pipe(
      filter((e) => e),
      switchMap(() => {
        return this.authQuery.role$.pipe(
          map((role) => {
            if (!role) {
              return this.router.createUrlTree(['/unauthorized']);
            }
            if ((route.data.roles as string[]).includes(role)) {
              return true;
            }
            return this.router.createUrlTree(['/main']);
          })
        );
      })
    );
  }
}
