import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Claims, ClaimsParsed, InternalUser } from '@expresssteuer/models';
import { FireAuthState, RoleState } from 'akita-ng-fire';

export interface AuthState
  extends FireAuthState<InternalUser>,
    RoleState<ClaimsParsed> {
  publicSessionId?: string | null;
  rawClaims?: Claims;
}

const initialState: AuthState = {
  uid: '',
  profile: { displayName: '', email: '', photoUrl: '' },
  loading: false,
  emailVerified: false,
  roles: {
    role: '',
    perms: {},
  },
  publicSessionId: (Math.random() + 1).toString(36).substring(2), // TODO
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(initialState);
  }
}
