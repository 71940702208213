import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { buildMeta } from '../build-meta/build-meta';
import { environment } from '../environments/environment';
@Component({
  selector: 'esti-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  buildMeta = buildMeta;

  constructor(private afStorage: AngularFireStorage) {}

  ngOnInit() {
    if (environment.emulator) {
      this.afStorage.storage.useEmulator('localhost', 9199);
      console.warn('Using storage emulator');
    }
  }
}
