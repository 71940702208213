import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthQuery } from '../../../state/auth/auth.query';

/**
 * Checks if the logged in user claims to have the necessary perms
 * 
 * - perm is defined in the data property of routes
 * - only checks on the top level that the perm key exists, does NOT validate contents
 * - waits for a logged in user (should be combined with AuthSyncGuard)
 * - users with the admin role are always allowed
 * - TODO: currently only one perm is supported
 * 
 * @example
  { 
    path: 'main',
    canActivate: [AuthSyncGuard, HasRoleGuard],
    data: { perm: 'iuser' },
  },
 */
@Injectable({
  providedIn: 'root',
})
export class HasPermGuard implements CanActivate {
  constructor(private authQuery: AuthQuery, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authQuery.isLoggedIn$.pipe(
      filter((e) => e),
      switchMap(() => {
        return this.authQuery.claims$.pipe(
          map((claims) => {
            if (!claims) {
              return this.router.createUrlTree(['/unauthorized']);
            }
            if (claims.role === 'admin') {
              //admins are always allowed
              return true;
            }
            if ((route.data.perm as string) in claims.perms) {
              return true;
            }
            return this.router.createUrlTree(['/main']);
          })
        );
      })
    );
  }
}
